.footer {
    background-color: #2B80F2;
}

.footer-dark {
    background-color: #ffffff;
}

.footer-logo {
    text-align: left;
}

.footer-logo img {
    width: 60%;
}

.contact-cta .mail-cta {
    margin-right: 12px;
    color: #fff;
}

.contact-cta .mail-cta:hover {
    color: #d0d0d0;
}

.contact-cta img:hover {
    filter: brightness(75%);
}

.copyright-footer p {
    color: #fff;
}

@media(max-width:768px) {
    .contact-cta {
        text-align: right;
    }

    .footer-top .copyright-footer {
        display: none;
    }

    .footer-logo img {
        width: 140px;
    }
}

@media(max-width:560px) {
    .contact-cta {
        text-align: right;
    }

    .footer-top .copyright-footer {
        display: none;
    }

    .footer-logo img {
        width: 121px;
    }

    .footer-top .linkdn-ref {
        display: none;
    }
}

.pre-footer {
    border-bottom: 1px #fff solid;
    margin-bottom: 20px;
}

.footer-dark .pre-footer {
    border-bottom: 1px #aeaeae54 solid;
    margin-bottom: 20px;
}

.footer-dark .copyright-footer p {
    color: #677294;
}

.footer-dark .contact-cta .mail-cta {
    margin-right: 12px;
    color: #677294;
}

.footer-dark .contact-cta .mail-cta:hover {
    color: #2b80f2;
}

.footer-dark .contact-cta img:hover {
    filter: brightness(60%);
}