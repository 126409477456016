.how-it-works-section {
  padding-left: 10%;
  padding-right: 10%;
  overflow: hidden;
}

.how-it-works-title span {
  color: #30BF2C;
}

@media (max-width: 576px) {
  #how-it-works .how-it-works-title {
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.25em;
  }
}