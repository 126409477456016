.contact-description {
    text-align: left;
}

.contact-description .title {
    color: #000000;
    /* font-size: 18px; */
    font-size: 1.875em;
    font-weight: 700;
    margin-bottom: 20px;
}

.contact-description .title span {
    color: #30BF2C;
}

.contact-description .body {
    color: #000000;
    font-size: 17px;
}

.contact-description .body span {
    color: #30BF2C;
    font-weight: 600;
}

.subscribe-error {
    color: #ff0000;
    font-size: 14px;
    text-align: left;
}

.contact-card {
    /* height: 320px; */
    padding: 35px 20px 25px 20px;
    -webkit-box-shadow: 0 0 15px rgba(12, 0, 46, 0.1);
    -moz-box-shadow: 0 0 15px rgba(12, 0, 46, 0.1);
    box-shadow: 0 0 15px rgba(12, 0, 46, 0.1);
    background-color: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
    /* height: 100%; */
}

.contact-form input {
    margin-bottom: 0px;
    border: 1px solid #E5E5EF;
    border-radius: 9px;
    padding: 12px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.00);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-form input:focus {
    border: 1px solid #2B80F2;
}

.contact-form input::placeholder {
    color: #9291A5;
}

.contact-form .first-row {
    padding: 8px;
}

.contact-form textarea {
    margin-bottom: 0px;
    border: 1px solid #E5E5EF;
    border-radius: 9px;
    padding: 12px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.00);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-form textarea:focus {
    border: 1px solid #2B80F2;
}

.contact-form textarea::placeholder {
    color: #9291A5;
    font-size: 16px;
}

.contact-form .submit-btn {
    background-color: #8dc63f;
    border-radius: 9px;
}

.contact-form .submit-btn:hover {
    background-color: #30BF2C;
    /* border-radius: 9px; */
}

.contact-form select {
    margin-bottom: 0px;
    border: 1px solid #E5E5EF;
    border-radius: 9px;
    padding: 12px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.00);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-form select option {
    background: #fff;
    border: #E5E5EF;
}

.container .alert {
    text-align: left;
}

.container .alert span {
    margin-left: 10px;
}

/* @media(max-width: 500px) {
    .contact-form button {
        background-color: #2B80F2;
        font-size: 15px;
        font-weight: 600;
        padding: 15px 26px;
    }
} */