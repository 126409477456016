.join-text span {
    font-weight: 500;
    font-size: 17px;
    color: #ffffff;
}

.answer-questions-section .answer-title h4 {
    margin-top: 40px;
    margin-left: 3px;
    margin-right: 3px;
}

.answer-questions-section .answer-title span {
    color: #30BF2C;
}

.answer-questions-section .answer-subtitle {
    color:#000;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 3px;
    margin-right: 3px;
}

.answer-questions-section .btn-container {
    margin-top: 40px;
    /* margin-bottom: 40px; */
    padding-bottom: 40px;
}

.answer-questions-section button {
    border-radius: 9px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.answer-questions-section {
    background-color: #2B80F2;
}

.contact-form button:hover {
    background-color: #8dc63f;
}

.question {
    background-color: rgba(43, 128, 242, 0.09);
    border-radius: 9px;
    padding: 12px 14px;
}

.question span {
    color: #000;
    font-style: italic;
    font-weight: 550;
    font-size: 17px;
}

.question svg {
    color: #000;
    margin-bottom: 10px;
    margin-right: 5px;
}

/* @media(max-width: 500px) {
    .contact-form button {
        background-color: #2B80F2;
        font-size: 15px;
        font-weight: 600;
        padding: 15px 26px;
    }
} */

.mailing-list {
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 53px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;
}

.answer-questions-section .contact-box {
    background-color: #fff;
    border-radius: 9px;
}