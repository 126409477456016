.use-case-title {
  font-size: 20px;
  margin-bottom: 52px;
}

.contact-btn-use-case {
  border-radius: 9px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.contact-box-use-case {
  display: flex;
  justify-content: center;
  /* background-color: #2B80F2; */
  /* background: linear-gradient(10deg, rgba(23,96,194,1) 0%, rgba(43,128,242,1) 47%, rgba(226,248,252,1) 100%); */
  background: linear-gradient(15deg, rgba(23,96,194,1) 0%, rgba(43,128,242,1) 47%, rgba(172,239,251,1) 100%); 
  border-radius: 14px;
}

.contact-box-use-case button {
  color: #ffffff;
  border: 2px solid #ffffff;
}

.contact-box-use-case button:hover {
  background-color: #ffffff;
  color: #2B80F2;
}

.contact-box-use-case-flex {
  /* display: flex; */
  box-shadow: 0 5px 17px rgba(12, 0, 46, 0.07);
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 49px;
}

.contact-box-use-case span {
  font-size: 18px;
  font-weight: 700;
}

.contact-box-use-case .contact-description {
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

@media(max-width: 459px) {
  .contact-box-use-case span {
    font-size: 18px;
    font-weight: 500;
  }
}