.banner-section {
    height: auto;
    /* background-color: #fff; */
    position: relative;
    padding-top: 130px;
    overflow: hidden;
    background-position: 0% 100%;
    background-size: cover;
}

.banner-section .banner-content {
    text-align: center;
    padding-bottom: 150px;
    position: relative;
    z-index: 1;
}

.pulse-back {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.tour-desc {
    margin-top: 4px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #fd5d5d !important;
}

@media (min-width: 992px) {
    .banner-section {
        height: 100vh;
    }

    .banner-section .banner-content {
        height: 100%;
        text-align: left;
        padding-bottom: 0;
    }
}

.banner-section img {
    padding-left: 5px;
}

.banner-text h1 {
    text-align: center;
    font-size: 30px;
    color: #000;
    margin-bottom: 15px;
    line-height: 1.2;
    /* text-align: center; */
}

.banner-text h1 .animated {
    color: #30BF2C;
    display: inline;
}

.banner-text p {
    color: #000;
    font-size: 20px;
    margin-top: 28px;
}

.banner-joint-image {
    position: relative;
}

.banner-joint-image .img1 {
    max-width: 100%;
    box-shadow: 0 10px 30px 0 rgba(13, 0, 46, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.banner-joint-image .img2 {
    position: absolute;
    left: 0px;
    top: -170px;
    box-shadow: 0 5px 15px 0 rgba(13, 0, 46, 0.1);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

.banner-text button {
    border-radius: 9px;
}

.description-row {
    display: flex;
    align-items: center;
}

.banner-btn-tour {
    font-size: 16px;
}

@media (max-width: 454px) {
    .pulse-back {
        bottom: 67px;
    }
}

@media (max-width: 576px) {
    .banner-text h1 {
        font-size: 24px;
        line-height: 1.2;
    }

    .pulse-back {
        bottom: 67px;
    }

    .banner-text h1.title-ES {
        font-size: 24px;
        line-height: 1.4;
    }
}

@media (min-width: 576px) {
    .banner-text h1 {
        font-size: 33px;
        line-height: 1.2;
    }

    .banner-text h1.title-ES {
        font-size: 26px;
        line-height: 1.4;
    }

    .banner-joint-image .img2 {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
        left: 0px;
        top: -150px;
    }

    .banner-section {
        padding-top: 155px;
    }
}


@media (min-width: 768px) {
    .banner-section {
        padding-top: 190px;
    }

    .banner-text h1.title-ES {
        font-size: 32px;
        line-height: 1.4;
    }

}


@media (min-width: 992px) {
    .description-row {
        margin-top: 20px;
    }

    .banner-section {
        height: 100vh;
        padding-top: 0;
    }

    .banner-text h1.title-ES {
        font-size: 32px;
        line-height: 1.2;
    }

    .banner-section .banner-content {
        height: 100%;
        text-align: left;
        padding-bottom: 0;
    }

    .banner-image-content {
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        padding-left: 100px;
        padding-top: 100px;
        z-index: 2;
    }

    .banner-joint-image .img2 {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
        top: -150px;
        left: 10px;
    }
}

@media (min-width: 1200px) {
    .banner-joint-image .img2 {
        left: 50px;
        top: -100px;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
    }

    .banner-text h1 {
        font-size: 39px;
        line-height: 1.2;
    }

    .banner-text h1.title-ES {
        font-size: 38px;
        line-height: 1.3;
    }
}

@media (min-width: 1500px) {
    .banner-text h1 {
        font-size: 34px;
        line-height: 54px;
    }

    /* .banner-text {
        margin-right: 62px;
    } */

    .banner-text h1.title-ES {
        font-size: 38px;
        line-height: 1.3;
    }

    .banner-image-content {
        padding-top: 50px;
    }

    .banner-joint-image .img2 {
        top: -100px;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}