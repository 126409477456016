.square-container {
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0 20px;
}

.tab-header {
  display: flex;
  gap: 8px;
  margin-bottom: -10px;
  align-self: flex-start;
  margin-left: 20px;
  border-radius: 14px 14px 0 0;
  flex-wrap: nowrap;
  width: 100%;
}

.tab-button {
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 14px 14px 0 0;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, all 0.3s ease;
  white-space: normal;
  overflow: visible;
  height: auto;
  min-height: 40px;
}

.tab-button.active,
.tab-button:hover {
  color: #2b80f2;
  background-color: white;
  transform: translateY(-8px);
}

.tab-content {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 14px;
  background-color: white;
  position: relative;
  font-size: 14px;
  text-align: left;
  padding: 40px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.tab-content .text {
  flex: 1;
}

.tab-content img {
  max-width: 40%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

.tab-content h2 {
  font-size: 16px;
  margin-bottom: 20px;
  color: #30bf2c;
}

.tab-content p {
  margin: 10px 0;
  margin-bottom: 40px;
  color: black;
}

.checklist {
  list-style-type: none;
  padding-left: 0;
}

.checklist li {
  position: relative;
  padding-left: 40px;
  font-size: 1rem;
  color: #555;
}

.checklist li::before {
  content: url(/assets/images/checklist-icon.png);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2rem;
  color: green;
}

.checklist li:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .tab-content {
    flex-direction: column;
    gap: 20px;
  }

  .tab-content img {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .square-container {
    padding: 0 10px;
  }

  .tab-header {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
  }

  .tab-button {
    border-radius: 14px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .tab-content {
    padding: 20px;
  }

  .tab-content h2 {
    font-size: 14px;
  }

  .tab-content img {
    max-width: 100%;
  }

  .checklist li {
    font-size: 0.9rem;
  }

  .tab-header {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
  }
}
