.faq-section .mail-cta {
    color: #2B80F2;
}

.faq-section .mail-cta:hover {
    color: #8dc63f;
}

.faq-section h6 {
    color: #000000;
    font-weight: 500;
    font-size: 17px;
}