.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 40px 20px;
}

.header--fixed.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  z-index: 999;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.main-menu-area .navbar-brand1 {
  display: block;
  text-align: left;
}

.main-menu-area .navbar-brand2 {
  text-align: left;
}


.navbar-brand1 img {
  max-width: 75%;
}

.navbar-brand2 img {
  max-width: 75%;
}

.main-menu-area .navbar-brand2 {
  display: none;
}

.header--fixed.sticky.main-menu-area .navbar-brand1 {
  display: none;
}

.header--fixed.sticky.main-menu-area .navbar-brand2 {
  display: block;
}

.header-btn-text {
  font-size: 16px;
}

.menu-bar {
  width: 45px;
  height: 40px;
  position: relative;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.menu-bar span {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 3px;
  margin-top: -1px;
  margin-left: -15px;
  background-color: #fff;
}

.menu-bar span:nth-child(1) {
  margin-top: -9px;
}

.menu-bar span:nth-child(3) {
  margin-top: 7px;
}

/* nav-menu */
.nav-menu .faq-head a,
.nav-menu .has-childmenu a,
.submenu-title {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 550;
  color: #000;
  display: block;
}

.submenu-title:hover {
  cursor: pointer;
}

.nav-menu .faq-head a:hover,
.nav-menu .has-childmenu a:hover,
.has-childmenu:hover .submenu-title {
  color: #2B80F2;
}


.header--fixed.sticky .nav-menu li a {
  color: #000;
}


.nav-menu .faq-head a span,
.nav-menu .has-childmenu a span {
  position: relative;
}

.nav-menu li a span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 1px;
  background-color: #fff;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav-menu li a:hover span:after,
.nav-menu li.is-current>a span:after {
  width: 100%;
}

/* Underline decoration sticky */
.header--fixed.sticky .nav-menu .faq-head a span:after,
.header--fixed.sticky .nav-menu .has-childmenu a span:after,
.header--fixed.sticky.main-menu-area .menu-bar span {
  background-color: #2B80F2;
}

/* Underline header no sticky */
.header--fixed .nav-menu .faq-head a span:after,
.header--fixed .nav-menu .has-childmenu a span:after,
.header--fixed.main-menu-area .menu-bar span {
  background-color: #2B80F2;
}

.header--fixed.sticky .faq-head a:hover,
.header--fixed.sticky .has-childmenu a:hover,
.header--fixed.sticky .nav-menu .has-childmenu:hover .submenu-title {
  color: #2B80F2;
}

.router-button,
button {
  padding: 10px 28px;
  font-size: 15px;
  font-weight: 550;
  background-color: #2B80F2;
  border: none;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 50px;
}

.router-button:hover,
button:hover {
  color: #fff;
  /* background-color: #1747f0; */
  background-color: #8dc63f;
}

.get-in-touch-header button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 9px;
}


@media (min-width: 992px) {
  .nav-menu li a {
    padding: 10px 10px;
  }

  .faq-head {
    margin-left: 44px;
    margin-right: 44px;
  }

  .language-div {
    margin-right: 44px;
  }

  /* submenu */
  .has-childmenu {
    position: relative;
  }

  .has-childmenu .submenu {
    list-style: none;
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 220px;
    background-color: #fff;
    padding: 5px 0;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.3s ease;
  }

  .has-childmenu .submenu li a {
    font-size: 85%;
    color: #000;
    padding: 10px 15px;
    display: block;
    text-align: left;
    font-weight: 600;
  }

  .has-childmenu .submenu li a:hover {
    color: #2B80F2
  }

  .has-childmenu:hover .submenu {
    transform: scaleY(1);
  }

  .home3 .has-childmenu .submenu li a:hover {
    background-color: #01d75f;
    color: white;
  }
}


/*  mobile menu  */

@media (max-width: 992px) {
  .logo {
    height: 30%;
    width: 30%;
  }

  .navbar-brand1 img {
    max-width: 100%;
  }

  .navbar-brand2 img {
    max-width: 100%;
  }

  .nav-menu li a:hover,
  .has-childmenu:hover .submenu-title {
    color: #000;
  }

  .language-div {
    position: relative;
    z-index: 9999;
  }


  .op-mobile-menu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 105vh;
    background-color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  .op-mobile-menu .m-menu-header {
    padding: 20px 30px;
  }

  .op-mobile-menu .close-button {
    position: relative;
    width: 35px;
    height: 35px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .op-mobile-menu .close-button:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 2px;
    margin-top: -1px;
    margin-left: -11px;
    background-color: #c7c6d1;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .op-mobile-menu .close-button:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 2px;
    margin-top: -1px;
    margin-left: -11px;
    background-color: #c7c6d1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center;
  }

  .mobile-menu-open .op-mobile-menu {
    left: 0;
  }

  .nav-menu .faq-head a,
  .nav-menu .has-childmenu a,
  .submenu-title {
    display: block;
    text-align: right;
    padding-right: 40px;
    font-weight: 550;
  }

  .language-div {
    display: block;
    text-align: right;
    padding-right: 30px;
    font-weight: 550;
  }

  .nav-menu li.is-current>a span:after {
    height: 2px;
    bottom: -2px;
    opacity: 1;
    width: 100%;
    background-color: #fff;
  }

  .nav-menu li>a.active span:after {
    height: 2px;
    bottom: -2px;
    opacity: 1;
    width: 100%;
  }

  .header--fixed.sticky .nav-menu li a {
    color: #000;
  }

  .header-style-2 .nav-menu li a {
    color: #fff;
  }

  .header--fixed.sticky .nav-menu li.is-current>a span:after {
    height: 2px;
    bottom: -2px;
    opacity: 1;
    width: 100%;
    background-color: #fff;
  }

  /* submenu */
  .has-childmenu {
    position: relative;
  }

  .has-childmenu .submenu {
    list-style: none;
    transform: scaleY(0);
    transform-origin: top;
    height: 0;
  }

  .has-childmenu .submenu li a {
    font-size: 85%;
    color: #000;
    padding: 10px 15px;
    display: block;
    text-align: right;
    padding-right: 45px;
    font-weight: 400;
  }

  .has-childmenu .submenu.active {
    transform: scaleY(1);
    height: auto;
    transition: all 0.3s ease;
  }

  .has-childmenu .submenu li a.active {
    background-color: transparent !important;
  }

  .has-childmenu .submenu.active span {
    height: 2px;
    bottom: -2px;
    opacity: 1;
  }

  button,
  .router-button {
    padding: 10px 80px;
  }
}

@media (max-width: 578px) {
  .logo {
    height: 55%;
    width: 55%;
  }
}


@media (min-width: 1200px) {
  .nav-menu li a {
    padding: 10px 15px;
  }
}



@media only screen and (max-width: 990px) {
  .op-mobile-menu .nav-menu>li {
    transform: translateX(-30px);
    transition: 0.7s ease;
    opacity: 0;
  }

  .mobile-menu-open .op-mobile-menu {
    left: 0;
  }

  .main-menu-area .nav-menu li.nav-item>a {
    padding: 15px 10px 15px 45px;
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #fff;
  }

  .mobile-menu-open {
    position: relative;
    overflow: hidden;
  }

  .mobile-menu-open .menu-bar {
    opacity: 0.1;
  }

  .mobile-menu-open .close-bar {
    transform: translateX(0px) !important;
    opacity: 1 !important;
    transition-delay: 0.2s !important;
  }

  .mobile-menu-open .mobile-menu-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(46, 60, 64, 0.9);
    z-index: 99;
    overflow: hidden;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li {
    transform: translateX(0px);
    transition-delay: 0s;
    opacity: 1;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(1) {
    transition-delay: 0.2s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(2) {
    transition-delay: 0.3s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(3) {
    transition-delay: 0.4s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(4) {
    transition-delay: 0.5s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(5) {
    transition-delay: 0.6s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(6) {
    transition-delay: 0.7s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(7) {
    transition-delay: 0.8s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(8) {
    transition-delay: 0.9s;
  }

  .mobile-menu-open .main-menu-area .nav-menu>li:nth-child(9) {
    transition-delay: 1s;
  }

  @keyframes scale-easeOutElastic {
    0% {
      transform: scale(1);
    }

    16% {
      transform: scale(-0.32);
    }

    28% {
      transform: scale(0.13);
    }

    44% {
      transform: scale(-0.05);
    }

    59% {
      transform: scale(0.02);
    }

    73% {
      transform: scale(-0.01);
    }

    88% {
      transform: scale(0);
    }

    100% {
      transform: scale(0);
    }
  }
}