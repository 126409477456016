.step-card {
    height: 365px;
}

.step-item {
    padding: 35px 20px 25px 20px;
    background-color: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 14px;
    border-color: rgba(0, 0, 0, 0.05);
    border-width: 1px;
    border-style: solid;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    height: 100%;

    -webkit-box-shadow: 0 0 10px rgba(12, 0, 46, 0.1);
    -moz-box-shadow: 0 0 10px rgba(12, 0, 46, 0.1);
    box-shadow: 0 0 10px rgba(12, 0, 46, 0.1);
}

.step-number {
    background-color: #2B80F2;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 8px;
}

.use-case-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 15px;
}

.step-item a {
    font-weight: 500;
    color: #2B80F2;
}

.step-item a:hover {
    color: #8dc63f;
}

.step-item a:hover span {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.step-item .step-head {
    padding: 0 0 6px;

    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-bottom: 10px;
}

/* IMAGES */
.card-step1 img {
    max-width: 240px;
}

.card-step2 img {
    max-width: 500px;
    margin-top: 25px;
}

.card-step3 img {
    max-width: 460px;
    margin-top: 18px;
}

.card-step4 img {
    max-width: 439px;
    margin-top: 18px;
}

/* MEDIA QUERIES */
@media (max-width: 1377px) {
    .card-step2 img {
        max-width: 500px;
        margin-top: 20px;
    }

    .card-step3 img {
        max-width: 467px;
    }
}

@media (max-width: 1324px) {
    .card-step2 img {
        max-width: 503px;
        margin-top: 22px;
    }

    .card-step3 img {
        max-width: 428px;
    }
}

@media (max-width: 1251px) {
    .card-step2 img {
        max-width: 481px;
        margin-top: 32px;
    }

    .card-step3 img {
        max-width: 388px;
    }
}


@media (max-width: 1210px) {
    .card-step4 img {
        max-width: 408px;
    }
}

@media (max-width: 1140px) {
    .card-step4 img {
        max-width: 382px;
    }

    .card-step2 img {
        max-width: 437px;
    }

    .card-step3 img {
        max-width: 382px;
    }
}

@media (max-width: 1070px) {
    .card-step4 img {
        max-width: 336px;
    }

    .card-step2 img {
        max-width: 424px;
    }

    .card-step3 img {
        max-width: 325px;
    }
}

@media (max-width: 1070px) {
    .card-step1 img {
        max-width: 210px;
    }
}


@media (max-width: 991px) {
    .step-item {
        display: block;
    }

    .step-card {
        height: 323px;
        margin-bottom: 30px;
    }

    .card-step1 img {
        max-width: 226px;
    }

    .card-step4 img {
        max-width: 408px;
        margin-top: 2px;
    }

    .card-step2 img {
        max-width: 470px;
        margin-top: 23px;
    }

    .card-step3 img {
        max-width: 426px;
    }
}

@media (max-width: 872px) {
    .card-step1 img {
        max-width: 205px;
    }
}

@media (max-width: 844px) {
    .card-step2 img {
        max-width: 444px;
    }
}

@media (max-width: 800px) {
    .card-step2 img {
        max-width: 422px;
    }
}

@media (max-width: 625px) {
    .card-step1 img {
        max-width: 203px;
    }
    .card-step2 img {
        max-width: 379px;
    }

    .card-step4 img {
        max-width: 364px;
    }

    .card-step3 img {
        max-width: 364px;
    }
}

@media (max-width: 600px) {
    .card-step1 img {
        max-width: 200px;
    }
}

@media (max-width: 560px) {
    .card-step4 img {
        max-width: 340px;
    }

    .card-step2 img {
        max-width: 335px;
        margin-top: 45px;
    }

    .card-step3 img {
        max-width: 346px;
    }
}


@media (max-width: 518px) {
    .card-step1 img {
        max-width: 180px;
    }

    .card-step2 img {
        max-width: 286px;
        margin-top: 50px;
    }

    .card-step3 img {
        max-width: 303px;
        margin-top: 30px;
    }

    .card-step4 img {
        max-width: 289px;
        margin-right: 29px;
        margin-top: 10px;
    }
}

@media (max-width: 465px) {
    .card-step3 img {
        max-width: 283px;
        margin-top: 30px;
    }
}

@media (max-width: 450px) {
    .card-step1 img {
        max-width: 170px;
    }
}