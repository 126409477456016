.use-case-section {
  position: relative;
  overflow: hidden;
  background-color: #fafafa;
}

.section-title {
  font-size: 14px;
  color: #30BF2C;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.use-cases-title span {
  color: #30BF2C;
}

@media (max-width: 576px) {
  #use-cases .use-cases-title {
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.25em;
  }
}