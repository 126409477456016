.use-case-card {
    height: 250px;
}

.use-case-item {
    padding: 35px 20px 25px 20px;
    background-color: #fff;
    border-radius: 14px;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    border-style: solid;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
    height: 100%;
}

.use-case-item:hover {
    background-color: #2B80F2;
}

.use-case-item:hover .use-case-head,
.use-case-item:hover .use-case-subtitle {
    color: #fff;
}

.img-active {
    display: none;
}

.use-case-item:hover .img-inactive {
    display: none;
}

.use-case-item:hover .img-active {
    display: inline;
}

/* .use-case-item img:first-child {
    display: none;
}
.use-case-item img:last-child {
    display: block;
}

.use-case-item:hover img:first-child {
    display: block;
}
.use-case-item:hover img:last-child {
    display: none;
} */

.use-case-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 15px;
}

.use-case-item a {
    font-weight: 500;
    color: #2B80F2;
}

.use-case-item a:hover {
    color: #8dc63f;
}

.use-case-item a:hover span {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.use-case-item .use-case-head {
    padding: 0 0 6px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-bottom: 10px;
    text-align: center;
}

.use-case-hr .use-case-head {
    font-size: 15px;
}

.use-case-item .use-case-subtitle {
    text-align: center;
    color: #000;
    font-size: 15px;
    margin-bottom: 12px;
}

.use-case-item .additional-flex {
    display: flex;
    align-self: flex-end
}

.use-case-hr .use-case-wrapper {
    margin-right: 0;
}

/* LINKS */
.use-case-link {
    text-align: left;
}

.use-case-hr-link {
    display: flex;
}

/* IMAGES */
.use-case-investors img {
    max-width: 35px;
    align-self: flex-end;
}

.use-case-hr img {
    max-width: 35px;
    align-self: flex-end;
}

.use-case-strategies img {
    max-width: 35px;
    align-self: flex-end;
}

.use-case-business img {
    max-width: 35px;
    align-self: flex-end;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
    .use-case-card {
        height: 340px;
    }

    .use-case-investors img {
        max-width: 35px;
    }

    .use-case-hr .use-case-subtitle {
        margin-bottom: 38px;
    }

    .use-case-business .use-case-subtitle {
        margin-bottom: 39px;
    }

    .use-case-business .use-case-head {
        margin-bottom: 37px;
    }

    .use-case-strategies .use-case-subtitle {
        margin-bottom: 64px;
    }

    .use-case-hr img {
        max-width: 35px;
    }

    .use-case-strategies img {
        max-width: 35px;
    }

    .use-case-business img {
        max-width: 35px;
    }
}

@media (max-width: 991px) {
    .use-case-item {
        display: block;
    }

    .use-case-card .use-case-head {
        margin-top: 15px;
    }

    .use-case-card {
        height: 240px;
        margin-bottom: 30px;
    }

    .use-case-business .use-case-head {
        margin-bottom: 10px;
    }

    .use-case-investors img {
        max-width: 35px;
    }

    .use-case-strategies img {
        max-width: 35px;
    }

    .use-case-hr img {
        max-width: 35px;
    }

    .use-case-hr-link {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }

    .use-case-hr-link a {
        text-align: left;
    }

    .use-case-business img {
        max-width: 35px;
    }
}

@media (max-width: 764px) {
    .use-case-hr-link img {
        max-width: 316px;
        margin-top: 20px;
    }
}

@media (max-width: 560px) {
    .use-case-investors img {
        max-width: 35px;
    }

    .use-case-link {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .use-case-hr img {
        max-width: 35px;
    }

    .use-case-strategies img {
        max-width: 35px;
    }

    .use-case-business img {
        max-width: 35px;
    }
}

@media (max-width: 500px) {
    .use-case-investors img {
        max-width: 35px;
    }

    .use-case-link {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .use-case-hr img {
        max-width: 35px;
    }

    .use-case-strategies img {
        max-width: 35px;
    }


    .use-case-business img {
        max-width: 35px;
    }
}

@media (max-width: 405px) {
    .use-case-card {
        height: 270px;
    }
}

@media (max-width: 380px) {
    .use-case-card {
        height: 290px;
    }
}

@media (max-width: 346px) {
    .use-case-card {
        height: 310px;
    }
}